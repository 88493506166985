$steps: 100;

@while $steps >= 0 {
  .LoaderBar.charge-#{$steps} {
    width: unquote($steps+"%")
  }
  $steps: $steps - 1
}

.LoaderBar-Wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  margin: auto;
  z-index: 9999;

  & > p{
    font-size: 2em;
    margin: 10px auto;
  }

  & > .LoaderBar {
    background-color: #0098a8;
    height: 15px;
    transition: width 300ms;
    border-radius: 5px;

  }
}