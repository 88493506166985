.Bottom-Add-Registry-Button{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover{
    background-color: rgba(222, 222, 222, 0.4);
    cursor: pointer;

    & .plus{
      transform: rotate(45deg);
      color: #0098a8;
      border-color: #0098a8;
    }

    & .subtitle-plus{
      color: #9a9a9a;
    }
  }

  & > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .plus{
    transition: transform 300ms, color 300ms, border-color 300ms;
    font-size: 40px;
    height: 40px;
    width: 40px;
    border-radius: 25px;
    border: 2px solid #145388;
    color: #145388;
    margin: 0 0 10px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & .subtitle-plus{
    transition: color 300ms;
    margin: 0 !important;
    color: #145388;
  }
}