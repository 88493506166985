@import "../../variables";

button.download-button{

  //position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  //top: 15px;
  //right: 55px;
  border: 0;
  background: white;
  z-index: 900;
  color: $dident-primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 300ms, background-color 300ms;
  -webkit-appearance: unset;
  box-shadow: -1px 1px 2px 2px rgba(0, 0, 0, 0.15);


  &:focus {
    outline: unset !important;
  }

  &:hover{
    color: white;
    background: $dident-primary-color;
    cursor: pointer;
  }

  &:active{
    transition: unset;
    background: $dident-secondary-color;
    color: white;
    transform: scale(0.90);
  }
}