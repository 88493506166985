$break-xsmall: 767px;
$break-small: 1199px;
$break-medium: 1439px;
$break-large: 9999px;

main {
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100vh - 132px);

  @media (max-width: $break-large) {
    min-height: calc(95vh - 132px);
  }

  @media (max-width: $break-medium) {
    min-height: calc(95vh - 132px);
  }

  @media (max-width: $break-small) {
    min-height: calc(95.5vh - 132px);
  }

  @media (max-width: $break-xsmall) {
    min-height: calc(95.5vh - 132px);
  }

}

.footer-page {

  z-index: 9000;
  width: 100%;
  bottom: 0;

  display: flex;
  justify-content: space-around;
  //background: white;
  padding: 1rem 0 0 0;
  //box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);

  &:not(.menu-mobile){
    width: calc(100% - 80px);
    //margin-left: 80px;
  }

  @media (max-width: $break-xsmall) {
    flex-direction: column;
    align-items: center;
    height: 150px;
  }
}