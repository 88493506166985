div.fullScreenImg{
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: rgba(38, 38, 38, 0.8);
  top: 0;
  cursor: pointer;
  transition: transform 300ms;
  transform: scale(0);

  &.mounted{
    transform: scale(1);
  }

  .img-wrapper {
    cursor: default;
    width: 90%;
    height: 90%;
    margin: auto;
    left: 0;
    right: 0;
    top: 4em;
    position: relative;
    //bottom:0;

    img {
      height: auto !important;
      max-width: 90%;
    }
  }
}

.container {
  margin-top: 10vh;
  height: 2vh;
  position: relative;
}

.center {
  margin: 0;
  position: absolute;
  
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.button-navigation-left {
  margin-right: 1rem;
  border-radius: 10px;
  background-color: #f9fafc !important; 
  color: black !important; 
  width: 3rem;
}

.button-navigation-right {
  margin-left: 1rem;
  border-radius: 10px;
  background-color: #f9fafc !important; 
  color: black !important; 
  width: 3rem;
}

.div-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}