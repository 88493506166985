@import "variables";
@import "./assets/css/sass/mixins";
.text-decoration-line-through {
  text-decoration: line-through;
}

.dident-text-light {
  color: $dident-light-color;
}
.dident-text-secondary {
  color: $dident-secondary-color;
}

.dident-cursor-pointer {
  cursor: pointer;
}

.avatar {
  img {
    width: 100%;
  }
}

.img_avatar {
  width: 110px;
  height: 120px;
  border-radius: 20px !important;
  margin: 0px !important;
}

.dident-tabs {
  color: $dident-light-color;
  text-transform: uppercase;
  font-size: 0.95em;
  & > a {
    &:hover {
      cursor: pointer;
    }

    &.active > span:first-child {
      border-bottom: 1px solid $dident-primary-color;
    }
  }

  & > .dident-nav-item {
    padding: 10px 15px 30px 15px;
    margin-right: 10px;
    height: 25px;
    position: relative;

    &.with-badge {
      margin-right: 0;
    }

    & .nav-badge {
      padding: 2px;
      position: relative;
      color: white;
      border-radius: 80%;
      min-width: 20px;
      height: 20px;
      top: -10px;
      left: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dident-tabs-content {
  & > div {
    opacity: 0;
    visibility: hidden;
    transition: opacity 400ms;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.dident-avatar-md {
  vertical-align: middle;
  background-position: center;
  background-size: cover;
  width: 50px;
  height: 50px;
}

.img-grey {
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.addAuthDoctor {
  cursor: pointer;
  color: $dident-light-color;
  font-size: 1.2em;
  &:hover {
    color: $dident-primary-color;
  }
}

.summary-info {
  font-size: 46px;
  line-height: 66px;
  color: #145388;
}

.cardShare-unfollow {
  font-size: 12px;
  line-height: 16px;
}

.cardShare-visibility {
  font-size: 16px;
  line-height: 16px;
}

.custom-control div > * {
  cursor: pointer;
}

.badge {
  color: white;
  margin: 1px;
  &.badge-radiography {
    background-color: $dident-badge-radiography-color !important;
  }
  &.badge-f {
    background-color: $dident-badge-f-color !important;
  }
  &.badge-i {
    background-color: $dident-badge-i-color !important;
  }
  &.badge-other {
    background-color: $dident-badge-other-color !important;
  }
  &.badge-stlModel {
    background-color: $dident-badge-stlModel-color !important;
  }
  &.badge-dicomViewer {
    background-color: $dident-badge-dicomViewer-color !important;
  }
  &.badge-tac {
    background-color: $dident-badge-tac-color !important;
  }
  &.badge-report {
    background-color: $dident-badge-report-color !important;
  }
  &.badge-dident-light {
    background-color: $dident-badge-light-color !important;
  }
}

.slick-dotted {
  position: absolute;
  left: -10px;
  top: 10px;
}

.icons-cards-row {
  i {
    font-size: 46px;
    line-height: 66px;
    color: #145388;
  }
  p {
    color: #8f8f8f;
    height: 30px;
    line-height: 26px;
  }
  .lead {
    color: #145388;
    margin-bottom: 0;
  }
  .font-weight-semibold {
    font-weight: 600;
  }
}

img.insistent-img-hover {
  &:hover {
    opacity: 0.6;
  }
}

.dident-overflow-hidden {
  overflow: hidden;
}

.w-xs-100-childs {
  & * {
    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.field-infoText {
  font-style: italic;
  color: #8f8f8f;
}

.team-viewer-icon {
  width: 50%;
  margin-bottom: 5px;
}

.msg-success {
  color: #37cd40;
  border: 1px solid #37cd40;
  padding: 8px;
}

.msg-error {
  color: red;
  border: 1px solid red;
  padding: 8px;
}

.authDoctors.iconsmind-Doctor {
  font-size: 20px;
  margin-right: 0.3em;
}
.checkboxsFinder {
  @include respond-below(sm) {
    display: flex;
    .form-group {
      width: auto;
    }
    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-left: 0;
      margin-right: 10px;
      overflow: hidden;
    }
  }
}

.selectorWrapper {
  height: 50px;
}
