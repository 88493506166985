
$min-height-carousel-xs: 500px;
$min-height-carousel-sm: 300px;
$min-height-carousel-md: 280px;
$min-height-carousel-lg: 380px;

//BreakPoints
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

.carousel.slide{
    & > .carousel-inner{
        width: auto;
        min-height: $min-height-carousel-xs;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;

        @media (max-width: $screen-xs-max) { min-height: $min-height-carousel-xs; }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { min-height: $min-height-carousel-sm; }
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { min-height: $min-height-carousel-md; }
        @media (min-width: $screen-lg-min) {min-height: $min-height-carousel-lg;}

        & p, & button, & img {
            opacity: 0;
            transition: opacity 200ms;
        }

        & .carousel-item.active{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            flex-direction: column;
            height: 100%;

            & > img {
                opacity: 1;
                max-height: 100%;
                max-width: 100%;
                height: auto;
                width: auto;
            }

            & p, & button{
                opacity: 1;
                margin: 8px 0 0 0;
            }
        }
    }
}
