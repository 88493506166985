.blurr-div {
    z-index: 10;
    margin: auto;
    margin-top: 15%;
    position: relative;
    height: 250px;
    width: 50%;
    border-radius: 15px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}