.wrapped-thumbnail{
  position: relative;
  height: 270px;
  width: 270px;
  float: left;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 250px;
    max-height: 250px;
  }
}