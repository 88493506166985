$dident-light-color: #9a9a9a;
$dident-secondary-color: #0098a8;
$dident-primary-color: #145388;

$dident-badge-radiography-color: #3f72d8;
$dident-badge-f-color: #47bd43;
$dident-badge-i-color: #77a8dc;
$dident-badge-other-color: #575855;
$dident-badge-stlModel-color: #ada91d;
$dident-badge-dicomViewer-color: #9558a5;
$dident-badge-tac-color: #e0582b;
$dident-badge-report-color: #eebc03;
$dident-badge-light-color: #b8b4b8;
