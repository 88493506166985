div.fullScreenVideo{
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: rgba(38, 38, 38, 0.8);
  top: 0;
  cursor: pointer;
  transition: transform 300ms;
  transform: scale(0);

  &.mounted{
    transform: scale(1);
  }

  .video-wrapper {
    cursor: default;
    width: 90%;
    height: auto;
    margin: auto;
    left: 0;
    right: 0;
    top: 4em;
    position: relative;
    //bottom:0;

    img {
      width: 100%;
    }
  }
}