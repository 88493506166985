.drop-wrapped{
    width: 100%;
    position: relative;

    & > .dropzone-loading-component {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 2px solid rgba(20, 83, 136, 0.2);
        border-radius: 50%;
        border-top-color: #145388;
        animation: drop-spinner 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        z-index: 1;
    }

    @keyframes drop-spinner {
        to { -webkit-transform: rotate(360deg); }
    }
    @-webkit-keyframes drop-spinner {
        to { -webkit-transform: rotate(360deg); }
    }

    & > .show-info-error-message{
        color:red;
        font-size: 16px;
        margin-top: 6px;
        margin-bottom: 0;
    }

}

.Dropzone {
    min-height: 200px;
    position: relative;
    width: 100%;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    /*border-radius: 50%;*/
    padding: 1em;
    font-size: 16px;

    &.drop-loading{
        opacity: 0.2;
    }
}

.withOutFiles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.FileInput {
    display: none;

}

/* *****************************************
    Thumbnail styles
* ******************************************/

.Thumbnail-container{
    position: relative;
    width: 120px;
    text-overflow: ellipsis;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.Thumbnail-img{
    width: 50%;
    height: auto;
}

.Thumbnail-title{
    text-align: center;
    margin-top: 2px;
    margin-bottom: 0px;
    word-break: break-word;
    padding: 0 8px;
}
.Thumbnail-size{
    font-size: 0.75rem;
}

.Thumbnail-trash {
    z-index: 2000;
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 1px solid #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    padding: 8px;

    & > * {
        max-height: 14px;
        max-width: 14px;
    }
    &:hover{
        opacity: 1;
        cursor: pointer;
    }
}