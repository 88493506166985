.background-image-wrapper{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image-wrapper > img.background-image-max-width {
    max-width: 100%;
    width: 100%;
}

.background-image-wrapper > img.background-image-max-height {
    max-height: 100%;
    height: 100%;
}