.thumbnail-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    width: 120px;
    margin-bottom: 1em
  }

  p.alt{
    max-width: 250px;
    word-break: break-all;
  }

  & div.thumbnailButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    & > button{
      margin: 0 5px 5px 5px;
    }
  }
}