.stl-wrapper-viewer{
  position: relative;
  height: 270px;
  width: 270px;
  float: left;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 250px;
    max-height: 250px;
  }

  & div.thumbnailButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    & > button{
      margin: 0 5px 5px 5px;
    }
  }
}

.sceneClassName{
  width: 100% !important;
  height: 100% !important;
  min-height: 250px;
  min-width: 250px;
}
