@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.checkbox {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.checkbox:hover, .checkbox *:hover{
    cursor: pointer;
}


.checkbox label {
    text-transform: inherit;
    margin: 1px 0px 0px 0px;
    padding: 0px;
}

.checkbox input[type=checkbox] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: none;
}
.checkbox input[type=checkbox] + div {
    height: 20px;
    width: 20px;
    border: 2px solid #9DA7B0;
    border-radius: 3px;
    background: linear-gradient(180deg, #FFFFFF, #EFEFEF);
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.checkbox  input[type=checkbox].checked + div {
    border: 2px solid #145388;
    background-color: #145388;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
}

.checkbox  input[type=checkbox] + div .checkmark-icon{
    opacity: 0;
    font-size: 24px;
    transition: opacity 300ms, transform 150ms, color 300ms;
}

.checkbox  input[type=checkbox] + div .checkmark-icon {
    color: #145388 !important;
    
}
.checkbox  input[type=checkbox].checked + div .checkmark-icon {
    opacity: 1;
}

.checkbox.isDisabled  input[type=checkbox].checked + div{
    border: 2px solid #8f8f8f;
    background-color: #8f8f8f;
}

.checkbox.isDisabled  input[type=checkbox] + div .checkmark-icon, 
.checkbox.isDisabled  label {
    color: #8f8f8f !important;
}