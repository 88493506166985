.active.card-body {
  background-color: #efefef;
  color: #0098a8;
}

.card-body.changeRegistry{
  display: flex;
  align-items: center;
}

.card-body.changeRegistry:hover{
  cursor: pointer;
  background-color: #efefef;
}