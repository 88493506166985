.page-editor-style{
  padding: 0 20px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  background: white;
  font-size: 15px;
  margin-bottom: 5px;
  min-height: 200px;
  max-height: 50vh;
  cursor: text;
}